import React, { useEffect, useState } from "react";
import logo from "../assets/icons/logo.png";
import { HiMenuAlt2 } from "react-icons/hi";
import Sidebar from "./Sidebar";
const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else setIsScrolled(false);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <Sidebar
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
      />
      <header
        className={`w-full text-black bg-white/80 backdrop-blur-md  ${
          isScrolled && "bg-white text-black"
        }`}
      >
        <div className="w-full h-[3rem] md:flex hidden flex justify-between items-center 2xl:max-w-[110rem] max-w-[85rem] mx-auto ">
          <a
            href="/"
            className="md:flex hidden items-center space-x-2 md:space-x-10"
          >
            <img className="h-30 w-40" src={logo} />
          </a>

          <ul className="hidden items-center space-x-10 md:flex">
            <li className="headerLink">
              <a href="/">Home</a>
            </li>
            <li className="headerLink">
              <a href="/#about">About</a>
            </li>
            <li className="headerLink">
              <a href="/#services">Services</a>
            </li>
            <li className="headerLink">
              <a href="/#contact">Contact</a>
            </li>
            <li className="headerLink">
              <a href="/team">Team</a>
            </li>
            <li className="headerLink">
              <a href="/testimonials">Testimonials</a>
            </li>
            <li className="headerLink">
              <div className="relative inline-block text-left">
                <div>
                  <button
                    type="button"
                    className="inline-flex justify-center w-full headerLink px-4 py-2 items-center"
                    id="menu-button"
                    aria-expanded="true"
                    aria-haspopup="true"
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    Forms
                    <svg
                      className="-mr-1 ml-2 h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>

                {isOpen && (
                  <div
                    className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="menu-button"
                    tabIndex="-1"
                  >
                    <div className="py-1" role="none">
                      {/* Dropdown items */}
                      <a
                        href="/job"
                        className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100"
                        role="menuitem"
                        tabIndex="-1"
                        id="menu-item-0"
                      >
                        Job Application
                      </a>
                      <a
                        href="/prp"
                        className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100"
                        role="menuitem"
                        tabIndex="-1"
                        id="menu-item-1"
                      >
                        PRP Referral Form
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </li>
            <a href="appointment" className="">
              <button class="px-4 py-4 font-medium tracking-wide text-white  capitalize transition-colors duration-300 transform bg-black rounded-lg ">
                <p className="">Appointment</p>
              </button>
            </a>
          </ul>
        </div>
        <div className="flex md:hidden justify-between items-center space-x-2 md:space-x-10">
          <img className="h-15 w-20" src={logo} />
          <button onClick={() => setIsSidebarOpen(!isSidebarOpen)} className="">
            <HiMenuAlt2 className="w-7 h-7" />
          </button>
        </div>
      </header>
    </div>
  );
};

export default Navbar;
