import React from "react";

const HelpHotline = () => {
  return (
    <div>
      <div class="flex  ">
        <div class="w-full md:h-[20rem] bg-cover bg-center">
          <div class="w-full h-full md:flex  justify-center items-center bg-white/100 backdrop-brightness-100 ">
            <div className="p-8">
              <div className="flex justify-start">
                <div className="md:flex justify-between items-center max-w-[69rem]">
                  <div className=" bg-customblue p-[5rem] ">
                    <p className="text-customgray text-[30px]">
                      988 Suicide and Crisis Lifeline - If you or someone you
                      know is struggling or in crisis, help is available. Call
                      or text <span className="underline font-bold text-white">988</span> or chat <a className="underline cursor-pointer text-white font-bold" href="https://988lifeline.org" target="blank">988lifeline.org</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpHotline;
