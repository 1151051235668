import React, { useState, useEffect } from 'react';
import picture2 from "../../assets/images/gallery/picture2.jpeg"
import picture3 from "../../assets/images/gallery/picture3.jpeg"
import picture4 from "../../assets/images/gallery/picture4.jpeg"

const Carousel = () => {
  const images = [
    picture2,
    picture3,
    picture4
  ]
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentImageIndex(currentImageIndex => (currentImageIndex + 1) % images.length);
    }, 6000); // Change image every 3 seconds

    return () => clearInterval(timer); // Clear the interval on component unmount
  }, [images.length]);

  return (
    <div className='flex justify-center'>
    <div className="relative flex justify-center items-center w-full h-[40rem] max-w-[80rem]">
      {images.map((image, index) => (
        <img
          key={index}
          src={image}
          alt={`Slide ${index}`}
          className={`absolute inset-0 w-full h-full object-contain transition-opacity duration-1000 ${index === currentImageIndex ? 'opacity-100' : 'opacity-0'}`}
        />
      ))}
    </div>
    </div>
  );
};

export default Carousel;
