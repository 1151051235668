import React, { useEffect } from "react";

const AuthorizeNetSeal = () => {
  useEffect(() => {
    const script1 = document.createElement("script");
    script1.type = "text/javascript";
    script1.language = "javascript";
    script1.innerHTML =
      'var ANS_customer_id="b7b1bde0-847c-4863-8fa7-ad68e4b9a7f4";';

    const script2 = document.createElement("script");
    script2.type = "text/javascript";
    script2.language = "javascript";
    script2.src = "https://verify.authorize.net:443/anetseal/seal.js";

    document.body.appendChild(script1);
    document.body.appendChild(script2);

    return () => {
      document.body.removeChild(script1);
      document.body.removeChild(script2);
    };
  }, []);

  return <div className="AuthorizeNetSeal" />;
};

export default AuthorizeNetSeal;
