import React from "react";

const AboutOrganization = () => {
  return (
    <div>
      <div class="flex  ">
        <div class="w-full md:h-[35rem] bg-center">
          <div class="w-full h-full md:flex  justify-center items-center bg-white/100 backdrop-brightness-100 ">
            <div className="p-8">
              <div className="flex justify-start">
                <div className="md:flex justify-between items-center max-w-[69rem]">
                  <div className="">
                    <p className="text-[49px] mb-5">
                      About <span className=" font-bold">Novum</span>
                    </p>
                    <p className="text-[20px]">
                      Novum Health Services Incorporated was created as a
                      non-profit 501 3(c) to help make mental health services
                      accessible and remove the stigma attached to mental health
                      in our community. We access individual patients on
                      case-by-case diagnosis and we tailor a treatment plan
                      according to unique needs and preferences. Our services
                      are rendered on evidence-based practice and are provided
                      by experienced and Licensed personnel to care for our
                      clients. Help is closer than you think at Novum Health
                      Services we provide client- centered care and treatments
                      unique to the individual. We are a wellness center
                      operating at the nexus of improving quality of life, both
                      mentally and physically through innovative health
                      promotion measures to ensure whole- body integration in
                      the community.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutOrganization;
