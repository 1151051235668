import Navbar from "../layout/Navbar";
import ceo from "../assets/images/gallery/ceo.jpeg";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Appointmentbanner from "../components/Home2/Appointmentbanner";
import Foote from "../components/Home2/Footer";

const Team = () => {
  return (
    <div>
      <ToastContainer
        position="top-center"
        hideProgressBar={true}
        closeOnClick
      />
      <Navbar />
      <div className="w-full  mx-auto  py-20">
      </div>{" "}
      <div className="flex justify-center">
        <div className=" max-w-[70rem]">
          <div className="flex justify-center">
            <div className="space-y-4">
              <img alt="CEO" src={ceo} className="w-[full] h-[30rem]" />
              <p className="text-center font-medium text-[30px]">
                Meet our Team - CEO
              </p>

              <p className="text-center font-medium">
                Dr. Yeside Ojo, DNP, FNP-C
              </p>
            </div>
          </div>

          <div className="mt-10 text-center flex justify-center text-[18px]">
            <p>
              Dr. Yeside Ojo, DNP, FNP-C, PMHNP-BC is a board-certified
              Psychiatric Nurse Practitioner whose aim is to provide
              client-centered care and treatments based on evidence-based
              guidelines by diagnosing according to the latest edition of the
              DSM and listening to the client’s concerns and symptoms.
              Effectively manage the clients’ symptoms by initiating, adjusting,
              and continuously monitoring medication administration. Improve the
              clients’ self-awareness and knowledge regarding mental health
              illnesses and medications by educating them on the proper use,
              drug interactions, and adverse drug reactions of medications and
              the course and prognosis of mental illnesses. Collaborate with
              other psychiatrists, medical case managers, therapists, nurses,
              and medical assistants to develop treatment plans. Perform mental
              health evaluations for adult patients with a wide range of
              psychiatric disorders and substance abuse disorders. Treatment of
              patients with addiction or unhealthy substance use. Proficient in
              the management of acute, chronic, mental health disorders across
              the lifespan including but not limited to Depression, Anxiety,
              Bipolar disorder, ADHD, Schizoaffective disorders, Schizophrenia,
              Neurocognitive disorders, Personality disorders, Opioid use
              disorder, and Alcohol use disorder. A proven ability to work well
              as part of a team.
            </p>
          </div>

          <div className="my-8">
            <p className="font-bold text-center text-[24px] my-5">
              OUR CLINICAL TEAM
            </p>
          <div className="text-center">
            
            <p>Psychiatric Mental Health Nurse Practitioners (PMHN-BC)</p>
            <p>- Licensed Clinical Social Workers (LCSW)</p>
            <p>- Licensed Clinical Professional Counselors (LCPC)</p>
            <p>- Licensed Masters Social Workers (LMSW)</p>
            <p>- Certified Associate Counselor-Alcohol and Drug (CAC-AD)</p>
            <p>- Rehabilitation Counselor (RC)</p>
          </div>
          </div>
        </div>
      </div>
      <Foote />
    </div>
  );
};

export default Team;
