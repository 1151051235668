import Navbar from "../layout/Navbar";
import ceo from "../assets/images/gallery/ceo.jpeg";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../components/Home2/Footer";
import { useState } from "react";

const Prp = () => {
  const [formData, setFormData] = useState({
    "Name (Client Information)": "",
    "Date of Referral (Client Information)": "",
    "Medical Assistance or Medicaid (Client Information)": "",
    "Initial or Concurrent (Client Information)": "",
    "Date Of Birth (Client Information)": "",
    "Zip (Client Information)": "",
    "State (Client Information)": "",
    "Home Address (number, street, apartment) (Client Information)": "",
    "City (Client Information)": "",
    "Age (Client Information)": "",
    "Race (Client Information)": "",
    "Gender (Client Information)": "",
    "Marital Status (Client Information)": "",
    "Home Address (number, street, apartment) (Parent/Guardian)": "",
    "Name (Parent/Guardian)": "",
    "Phone Number (Parent/Guardian)": "",
    "Zip (Parent/Guardian)": "",
    "State ((Parent/Guardian)": "",
    "City (Parent/Guardian)": "",
    "Name (Licensed Provider Information)": "",
    "Supervisor Name (if applicable) (Licensed Provider Information)": "",
    "Name of Referring Agency (Licensed Provider Information)": "",
    "Reasons For Referral": "",
    "If the client is not on medication, please specify why not?": "",
    "List of Medication": "",
    "Diagnosis made by": "",
    "Diagnosis Date": "",
    frequency: "",
    length: "",
    "Mark inability to establish/maintain independent competitive employment (documented functional impairments)": false,
    "Code Description": "",
    "Mark inability to perform instructional activities of daily living (meal prep, chores, shopping, money management, medication management)": false,
    "Mark inability to establish or maintain a personal support system": false,
    "Mark for frequent deficiencies of concentration, persistent or pace": false,
    "Mark inability to perform or maintain self-care (hygiene, grooming, nutrition, medical care, personal safety)": false,
    "Mark deficiencies in self-direction": false,
    "Mark inability to procure financial assistance to support community living": false,
  });

  const sendMessage = async (e) => {
    e.preventDefault();

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    };

    try {
      const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/email/prp`, options);
      if (!res.ok) {
        throw Error("error response");
      }
      toast.success("Job application submitted");
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
      throw error;
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    console.log(e.target.checked);
    setFormData((prevCheckboxState) => ({
      ...prevCheckboxState,
      [name]: checked,
    }));
  };

  return (
    <>
      <Navbar />
      <ToastContainer
        position="top-center"
        hideProgressBar={true}
        closeOnClick
      />
      <div className="w-full font-Poppins pt-[100px] text-[14px]">
        <h3 className="underline center text-center text-3xl lg:text-5xl font-bold"></h3>
        <h3 className="font-bold text-xl mt-4 text-center ">
          Novum Health Services Incorporated
        </h3>
        <h5 className="font-semibold text-center text-xs tracking-wider leading-7 mt-4 ">
          1404 Crain Hwy S,Glen Burnie, Maryland 21061 <br />
          (Tel: 443-698-8250)
        </h5>
        <h3 className="font-bold text-base mt-4 text-center px-[4%]">
          PSYCHIATRY REHABILITATION PROGRAM REFERRAL FORM
        </h3>
        <p className="text-center mt-3 px-[4%]">
          <b>Instructions:</b> Plese PRINT and fax the completed form to
          1-208-213-2790
        </p>
        <section className="mt-14 leading-normal w-full lg:max-w-[1280px] mx-auto px-[4%] sm:px-[50px]">
          <form
            action=""
            className="mt-8 w-full tracking-wide"
            onSubmit={sendMessage}
          >
            <h3 className="font-semibold ">Client Information</h3>
            <article className="flex flex-col gap-3 mt-3">
              <div className="flex sm:items-end flex-col sm:flex-row  gap-[10px] justify-between mt-[3px]">
                <h4 className="relative  bottom-[-7px]">Name:</h4>
                <input
                  type="text"
                  name="Name (Client Information)"
                  id=""
                  value={formData.Name}
                  onChange={handleInputChange}
                  required
                  className="border-t-0 border-r-0 w-[96%] border-l-0 border-b border-b-black"
                />
              </div>
              <article className="flex items-start sm:items-center w-full flex-col sm:flex-row sm:gap-8 lg:gap-[10%]">
                <div className="flex sm:items-end sm:basis-1/2 flex-col sm:flex-row  gap-[10px] justify-between mt-[3px]">
                  <h4 className="relative bottom-[-7px]">Date of Referral:</h4>
                  <input
                    type="text"
                    onChange={handleInputChange}
                    required
                    name="Date of Referral (Client Information)"
                    id=""
                    value={formData["Date of Referral"]}
                    className="border-t-0 w-[96%] border-r-0 border-l-0 border-b border-b-black"
                  />
                </div>
                <div className="flex sm:items-end sm:basis-1/2 flex-col sm:flex-row  gap-[10px] justify-between mt-[3px]">
                  <h4 className="relative bottom-[-7px]">
                    Initial or Concurrent:
                  </h4>
                  <input
                    required
                    onChange={handleInputChange}
                    type="text"
                    name="Initial or Concurrent (Client Information)"
                    id=""
                    value={
                      formData["Initial or Concurrent (Client Information)"]
                    }
                    className="border-t-0 border-r-0 w-[96%] border-l-0 border-b border-b-black"
                  />
                </div>
              </article>
              <article className="flex items-start sm:items-center w-full flex-col sm:flex-row sm:gap-8 lg:gap-[10%]">
                <div className="flex sm:items-end flex-col sm:flex-row sm:basis-2/4  gap-[10px] justify-between mt-[3px]">
                  <h4 className="relative bottom-[-7px]">
                    Medical Assistance or Medicaid:
                  </h4>
                  <input
                    type="text"
                    name="Medical Assistance or Medicaid (Client Information)"
                    required
                    value={
                      formData[
                        "Medical Assistance or Medicaid (Client Information)"
                      ]
                    }
                    onChange={handleInputChange}
                    id=""
                    className="border-t-0 w-[96%] border-r-0 border-l-0 border-b border-b-black"
                  />
                </div>
                <div className="flex sm:items-end flex-col sm:basis-1/4 sm:flex-row  gap-[10px] justify-between mt-[3px]">
                  <h4 className="relative  bottom-[-7px]">SSN:</h4>
                  <input
                    name="SSN (Client Information)"
                    type="text"
                    id=""
                    value={formData["SSN (Client Information)"]}
                    onChange={handleInputChange}
                    required
                    className="border-t-0 w-[96%] border-r-0 border-l-0 border-b border-b-black"
                  />
                </div>
                <div className="flex sm:items-end flex-col sm:basis-1/4  sm:flex-row  gap-[10px] justify-between mt-[3px]">
                  <h4 className="relative  bottom-[-7px]">Date of Birth:</h4>
                  <input
                    type="date"
                    name="Date Of Birth (Client Information)"
                    id=""
                    value={formData["Date Of Birth (Client Information)"]}
                    onChange={handleInputChange}
                    required
                    className="border-t-0 w-[96%] border-r-0 border-l-0 border-b border-b-black"
                  />
                </div>
              </article>
              <article className="flex items-start sm:items-center w-full flex-col sm:flex-row sm:gap-8 lg:gap-[10%]">
                <div className="flex sm:items-end flex-col sm:flex-row sm:basis-1/4  gap-[10px] justify-between mt-[3px]">
                  <h4 className="relative bottom-[-7px]">Age</h4>
                  <input
                    type="number"
                    name="Age (Client Information)"
                    required
                    value={formData["Age (Client Information)"]}
                    onChange={handleInputChange}
                    id=""
                    className="border-t-0 w-[96%] border-r-0 border-l-0 border-b border-b-black"
                  />
                </div>
                <div className="flex sm:items-end flex-col sm:basis-1/4 sm:flex-row  gap-[10px] justify-between mt-[3px]">
                  <h4 className="relative  bottom-[-7px]">Race:</h4>
                  <input
                    name="Race (Client Information)"
                    type="text"
                    id=""
                    value={formData["Race (Client Information)"]}
                    onChange={handleInputChange}
                    required
                    className="border-t-0 w-[96%] border-r-0 border-l-0 border-b border-b-black"
                  />
                </div>
                <div className="flex sm:items-end flex-col sm:basis-1/4  sm:flex-row  gap-[10px] justify-between mt-[3px]">
                  <h4 className="relative  bottom-[-7px]">Gender:</h4>
                  <input
                    type="text"
                    name="Gender (Client Information)"
                    id=""
                    value={formData["Gender (Client Information)"]}
                    onChange={handleInputChange}
                    required
                    className="border-t-0 w-[96%] border-r-0 border-l-0 border-b border-b-black"
                  />
                </div>
                <div className="flex sm:items-end flex-col sm:basis-1/4  sm:flex-row  gap-[10px] justify-between mt-[3px]">
                  <h4 className="relative  bottom-[-7px]">Marital Status:</h4>
                  <input
                    type="text"
                    name="Marital Status (Client Information)"
                    id=""
                    value={formData["Marital Status (Client Information)"]}
                    onChange={handleInputChange}
                    required
                    className="border-t-0 w-[96%] border-r-0 border-l-0 border-b border-b-black"
                  />
                </div>
              </article>
              <div className="flex sm:items-end flex-col sm:basis-1/4  sm:flex-row  gap-[10px] justify-between mt-[3px]">
                <h4 className="relative  bottom-[-7px]">
                  Home Address (number, street, apartment):
                </h4>
                <input
                  type="text"
                  name="Home Address (number, street, apartment) (Client Information)"
                  id=""
                  value={
                    formData[
                      "Home Address (number, street, apartment) (Client Information)"
                    ]
                  }
                  onChange={handleInputChange}
                  required
                  className="border-t-0 w-[96%] border-r-0 border-l-0 border-b border-b-black"
                />
              </div>
              <article className="flex items-start sm:items-center w-full flex-col sm:flex-row sm:gap-8 lg:gap-[10%]">
                <div className="flex sm:items-end flex-col sm:flex-row sm:basis-1/3  gap-[10px] justify-between mt-[3px]">
                  <h4 className="relative bottom-[-7px]">City</h4>
                  <input
                    type="text"
                    name="City (Client Information)"
                    required
                    value={formData["City (Client Information)"]}
                    onChange={handleInputChange}
                    id=""
                    className="border-t-0 w-[96%] border-r-0 border-l-0 border-b border-b-black"
                  />
                </div>
                <div className="flex sm:items-end flex-col sm:basis-1/3 sm:flex-row  gap-[10px] justify-between mt-[3px]">
                  <h4 className="relative  bottom-[-7px]">State:</h4>
                  <input
                    name="State (Client Information)"
                    type="text"
                    id=""
                    value={formData["State (Client Information)"]}
                    onChange={handleInputChange}
                    required
                    className="border-t-0 w-[96%] border-r-0 border-l-0 border-b border-b-black"
                  />
                </div>
                <div className="flex sm:items-end flex-col sm:basis-1/3  sm:flex-row  gap-[10px] justify-between mt-[3px]">
                  <h4 className="relative  bottom-[-7px]">Zip:</h4>
                  <input
                    type="tel"
                    name="Zip (Client Information)"
                    id=""
                    value={formData["Zip (Client Information)"]}
                    onChange={handleInputChange}
                    required
                    className="border-t-0 w-[96%] border-r-0 border-l-0 border-b border-b-black"
                  />
                </div>
              </article>
            </article>
            <section className="mt-[72px]">
              <h3 className="font-semibold ">Parent/Guardian</h3>
              <article className="flex flex-col gap-3 mt-3">
                <div className="flex sm:items-end flex-col sm:flex-row  gap-[10px] justify-between mt-[3px]">
                  <h4 className="relative  bottom-[-7px]">Name:</h4>
                  <input
                    type="text"
                    name="Name (Parent/Guardian)"
                    id=""
                    value={formData.Name}
                    onChange={handleInputChange}
                    required
                    className="border-t-0 border-r-0 w-[96%] border-l-0 border-b border-b-black"
                  />
                </div>
                <div className="flex sm:items-end flex-col sm:basis-1/4  sm:flex-row  gap-[10px] justify-between mt-[3px]">
                  <h4 className="relative  bottom-[-7px]">
                    Home Address (number, street, apartment):
                  </h4>
                  <input
                    type="text"
                    name="Home Address (number, street, apartment) (Parent/Guardian)"
                    id=""
                    value={
                      formData[
                        "Home Address (number, street, apartment) (Parent/Guardian)"
                      ]
                    }
                    onChange={handleInputChange}
                    required
                    className="border-t-0 w-[96%] border-r-0 border-l-0 border-b border-b-black"
                  />
                </div>
                <article className="flex items-start sm:items-center w-full flex-col sm:flex-row sm:gap-8 lg:gap-[10%]">
                  <div className="flex sm:items-end flex-col sm:flex-row sm:basis-1/3  gap-[10px] justify-between mt-[3px]">
                    <h4 className="relative bottom-[-7px]">City</h4>
                    <input
                      type="text"
                      name="City (Parent/Guardian)"
                      required
                      value={formData["City (Parent/Guardian)"]}
                      onChange={handleInputChange}
                      id=""
                      className="border-t-0 w-[96%] border-r-0 border-l-0 border-b border-b-black"
                    />
                  </div>
                  <div className="flex sm:items-end flex-col sm:basis-1/3 sm:flex-row  gap-[10px] justify-between mt-[3px]">
                    <h4 className="relative  bottom-[-7px]">State:</h4>
                    <input
                      name="State ((Parent/Guardian)"
                      type="text"
                      id=""
                      value={formData["State (Parent/Guardian)"]}
                      onChange={handleInputChange}
                      required
                      className="border-t-0 w-[96%] border-r-0 border-l-0 border-b border-b-black"
                    />
                  </div>
                  <div className="flex sm:items-end flex-col sm:basis-1/3  sm:flex-row  gap-[10px] justify-between mt-[3px]">
                    <h4 className="relative  bottom-[-7px]">Zip:</h4>
                    <input
                      type="tel"
                      name="Zip (Parent/Guardian)"
                      id=""
                      value={formData["Zip (Parent/Guardian)"]}
                      onChange={handleInputChange}
                      required
                      className="border-t-0 w-[96%] border-r-0 border-l-0 border-b border-b-black"
                    />
                  </div>
                </article>

                <article className="flex items-start sm:items-center w-full flex-col sm:flex-row sm:gap-8 lg:gap-[10%]">
                  <div className="flex sm:items-end sm:basis-1/2 flex-col sm:flex-row  gap-[10px] justify-between mt-[3px]">
                    <h4 className="relative bottom-[-7px]">Phone number:</h4>
                    <input
                      type="text"
                      onChange={handleInputChange}
                      required
                      name="Phone Number (Parent/Guardian)"
                      id=""
                      value={formData["Phone Number (Parent/Guardian)"]}
                      className="border-t-0 w-[96%] border-r-0 border-l-0 border-b border-b-black"
                    />
                  </div>
                  <div className="flex sm:items-end sm:basis-1/2 flex-col sm:flex-row  gap-[10px] justify-between mt-[3px]">
                    <h4 className="relative bottom-[-7px]">Email:</h4>
                    <input
                      required
                      onChange={handleInputChange}
                      type="email"
                      name="Email (Parent/Guardian) "
                      id=""
                      value={formData["Email (Parent/Guardian)"]}
                      className="border-t-0 border-r-0 w-[96%] border-l-0 border-b border-b-black"
                    />
                  </div>
                </article>
              </article>
            </section>
            <section className="mt-[72px]">
              <h3 className="font-semibold ">Licensed Provider Information</h3>
              <article className="flex flex-col gap-3 mt-3">
                <article className="flex items-start sm:items-center w-full flex-col sm:flex-row sm:gap-8 lg:gap-[10%]">
                  <div className="flex sm:items-end flex-col sm:flex-row sm:basis-1/2  gap-[10px] justify-between mt-[3px]">
                    <h4 className="relative  bottom-[-7px]">Name:</h4>
                    <input
                      type="text"
                      name="Name (Licensed Provider Information)"
                      id=""
                      value={formData["Name (Licensed Provider Information)"]}
                      onChange={handleInputChange}
                      required
                      className="border-t-0 border-r-0 w-[96%] border-l-0 border-b border-b-black"
                    />
                  </div>
                  <div className="flex sm:items-end flex-col sm:basis-1/2  sm:flex-row  gap-[10px] justify-between mt-[3px]">
                    <h4 className="relative  bottom-[-7px]">
                      Credentials of Provider:
                    </h4>
                    <input
                      type="text"
                      name="Credentials of Provider (Licensed Provider Information)"
                      id=""
                      value={
                        formData[
                          "Credentials of Provider (Licensed Provider Information)"
                        ]
                      }
                      onChange={handleInputChange}
                      required
                      className="border-t-0 w-[96%] border-r-0 border-l-0 border-b border-b-black"
                    />
                  </div>
                </article>
                <div className="flex sm:items-end flex-col sm:flex-row sm:w-full gap-[10px] justify-between mt-[3px]">
                  <h4 className="relative  bottom-[-7px]">
                    Supervisor Name (if applicable):
                  </h4>
                  <input
                    type="text"
                    name="Supervisor Name (if applicable) (Licensed Provider Information)"
                    id=""
                    value={formData.suo}
                    onChange={handleInputChange}
                    required
                    className="border-t-0 border-r-0 w-[96%] border-l-0 border-b border-b-black"
                  />
                </div>
                <div className="flex sm:items-end flex-col sm:flex-row sm:w-full gap-[10px] justify-between mt-[3px]">
                  <h4 className="relative  bottom-[-7px]">
                    Name of Referring Agency:
                  </h4>
                  <input
                    type="text"
                    name="Name of Referring Agency (Licensed Provider Information)"
                    id=""
                    value={
                      formData[
                        "Name of Referring Agency (Licensed Provider Information)"
                      ]
                    }
                    onChange={handleInputChange}
                    required
                    className="border-t-0 border-r-0 w-[96%] border-l-0 border-b border-b-black"
                  />
                </div>
              </article>
            </section>

            <section className="mt-8">
              <h4 className="font-semibold ">
                Length and Frequency of Service
              </h4>
              <div className="mt-5">
                <h4 className="font-semibold">Length</h4>
                <div className="flex items-center gap-3 flex-wrap mt-3">
                  <label htmlFor="">
                    <input
                      type="radio"
                      value="< 1 month"
                      onChange={handleInputChange}
                      name="length"
                      id=""
                      className="mr-2"
                    />
                    &gt; 1 month
                  </label>
                  <label htmlFor="">
                    <input
                      type="radio"
                      value="2-3 months"
                      onChange={handleInputChange}
                      required
                      name="length"
                      id=""
                      className="mr-2"
                    />
                    2-3 months
                  </label>
                  <label htmlFor="">
                    <input
                      type="radio"
                      value="4-6 months"
                      onChange={handleInputChange}
                      required
                      name="length"
                      id=""
                      className="mr-2"
                    />
                    4-6 months
                  </label>
                  <label htmlFor="">
                    <input
                      type="radio"
                      value="7-12 months"
                      onChange={handleInputChange}
                      required
                      name="length"
                      id=""
                      className="mr-2"
                    />
                    7-12 month
                  </label>
                  <label htmlFor="">
                    <input
                      type="radio"
                      name="length"
                      id=""
                      value="12 months or > "
                      onChange={handleInputChange}
                      required
                      className="mr-2"
                    />
                    12 months or &gt;
                  </label>
                </div>
              </div>
              <div className="mt-5">
                <h4 className="font-semibold">Frequency</h4>
                <div className="flex items-center gap-3 flex-wrap mt-3">
                  <label htmlFor="">
                    <input
                      type="radio"
                      name="frequency"
                      onChange={handleInputChange}
                      id=""
                      value="1x/ week"
                      required
                      className="mr-2"
                    />
                    1x/ week
                  </label>
                  <label htmlFor="">
                    <input
                      type="radio"
                      name="frequency"
                      value="2x/ week"
                      onChange={handleInputChange}
                      id=""
                      required
                      className="mr-2"
                    />
                    2x/ week
                  </label>
                  <label htmlFor="">
                    <input
                      type="radio"
                      name="frequency"
                      id=""
                      value="1x/ month"
                      required
                      onChange={handleInputChange}
                      className="mr-2"
                    />
                    1x/ month
                  </label>
                  <label htmlFor="">
                    <input
                      value="3x/ month"
                      type="radio"
                      name="frequency"
                      required
                      onChange={handleInputChange}
                      id=""
                      className="mr-2"
                    />
                    3x/ month
                  </label>
                  <label htmlFor="">
                    <input
                      type="radio"
                      name="frequency"
                      id=""
                      value="1x6/ month"
                      required
                      onChange={handleInputChange}
                      className="mr-2"
                    />
                    1x6/ month
                  </label>
                </div>
              </div>
              <div className="mt-7">
                <p className="mb-3">
                  To be eligible for PRP services, the client must have at least
                  3 of the documented functional impairments listed below:
                </p>
                <div className="flex flex-col gap-3">
                  <div className="flex items-center gap-3">
                    <input
                      type="checkbox"
                      name="Mark inability to establish/maintain independent competitive employment (documented functional impairments)"
                      id=""
                      onChange={handleCheckboxChange}
                      value={
                        formData[
                          "Mark inability to establish/maintain independent competitive employment (documented functional impairments)"
                        ]
                      }
                    />
                    <p>
                      Mark inability to establish/maintain independent
                      competitive employment
                    </p>
                  </div>
                  <div className="flex items-center gap-3">
                    <input
                      name="Mark inability to perform instructional activities of daily living (meal prep, chores, shopping, money management, medication management)"
                      type="checkbox"
                      id=""
                      onChange={handleCheckboxChange}
                      value={
                        formData[
                          "Mark inability to perform instructional activities of daily living (meal prep, chores, shopping, money management, medication management)"
                        ]
                      }
                    />
                    <p>
                      Mark inability to perform instructional activities of
                      daily living (meal prep, chores, shopping, money
                      management, medication management)
                    </p>
                  </div>
                  <div className="flex items-center gap-3">
                    <input
                      name="Mark inability to establish or maintain a personal support system"
                      type="checkbox"
                      id=""
                      value={
                        formData[
                          "Mark inability to establish or maintain a personal support system"
                        ]
                      }
                      onChange={handleCheckboxChange}
                    />
                    <p>
                      Mark inability to establish or maintain a personal support
                      system
                    </p>
                  </div>
                  <div className="flex items-center gap-3">
                    <input
                      type="checkbox"
                      name="Mark for frequent deficiencies of concentration, persistent or pace"
                      id=""
                      onChange={handleCheckboxChange}
                      value={
                        formData[
                          "Mark for frequent deficiencies of concentration, persistent or pace"
                        ]
                      }
                    />
                    <p>
                      Mark for frequent deficiencies of concentration,
                      persistent or pace
                    </p>
                  </div>
                  <div className="flex items-center gap-3">
                    <input
                      type="checkbox"
                      name="Mark inability to perform or maintain self-care (hygiene, grooming, nutrition, medical care, personal safety)"
                      id=""
                      value={
                        formData[
                          "Mark inability to perform or maintain self-care (hygiene, grooming, nutrition, medical care, personal safety)"
                        ]
                      }
                      onChange={handleCheckboxChange}
                    />
                    <p>
                      Mark inability to perform or maintain self-care (hygiene,
                      grooming, nutrition, medical care, personal safety)
                    </p>
                  </div>
                  <div className="flex items-center gap-3">
                    <input
                      type="checkbox"
                      name="Mark deficiencies in self-direction"
                      id=""
                      onChange={handleCheckboxChange}
                      value={formData["Mark deficiencies in self-direction"]}
                    />
                    <p>Mark deficiencies in self-direction</p>
                  </div>
                  <div className="flex items-center gap-3">
                    <input
                      type="checkbox"
                      name="Mark inability to procure financial assistance to support community living"
                      id=""
                      onChange={handleCheckboxChange}
                      value={
                        formData[
                          "Mark inability to procure financial assistance to support community living"
                        ]
                      }
                    />
                    <p>
                      Mark inability to procure financial assistance to support
                      community living
                    </p>
                  </div>
                </div>
              </div>
              <article className="mt-8">
                <h3 className="font-semibold">Primary Diagnosis</h3>
                <div className="flex sm:items-end flex-col   sm:flex-row  gap-[16px] justify-between mt-[3px]">
                  <h4 className="relative  bottom-[-7px]">Code Description</h4>
                  <textarea
                    type="text"
                    name="Code Description"
                    id=""
                    rows={3}
                    cols={50}
                    value={formData["Code Description"]}
                    onChange={handleInputChange}
                    required
                    className="border-t-0 w-[96%]  border-r-0 border-l-0 border-b border-b-black"
                  ></textarea>
                </div>
                <article className="flex mt-6 items-start sm:items-center w-full flex-col sm:flex-row sm:gap-8 lg:gap-[10%]">
                  <div className="flex sm:items-end flex-col sm:flex-row sm:basis-[70%]  gap-[10px] justify-between mt-[3px]">
                    <h4 className="relative  bottom-[-7px]">
                      Diagnosis made by:
                    </h4>
                    <input
                      type="text"
                      name="Diagnosis made by"
                      id=""
                      value={formData["Diagnosis made by"]}
                      onChange={handleInputChange}
                      required
                      className="border-t-0 border-r-0 w-[96%] border-l-0 border-b border-b-black"
                    />
                  </div>
                  <div className="flex sm:items-end flex-col sm:basis-[30%]  sm:flex-row  gap-[10px] justify-between mt-[3px]">
                    <h4 className="relative  bottom-[-7px]">Date:</h4>
                    <input
                      type="text"
                      name="Diagnosis Date"
                      id=""
                      value={formData["Diagnosis Date"]}
                      onChange={handleInputChange}
                      required
                      className="border-t-0 w-[96%] border-r-0 border-l-0 border-b border-b-black"
                    />
                  </div>
                </article>
              </article>
            </section>
            <section className="mt-8">
              <h4 className="font-semibold ">List of Medication</h4>

              <textarea
                type="text"
                name="List of Medication"
                id=""
                rows={4}
                value={formData["List of Medication"]}
                onChange={handleInputChange}
                required
                className="w-[96%]  border border-black h-[160px] p-3"
              ></textarea>
            </section>
            <section className="mt-8">
              <h4 className="font-semibold ">
                If the client is not on medication, please specify why not?
              </h4>

              <textarea
                type="text"
                name="If the client is not on medication, please specify why not?"
                id=""
                rows={4}
                value={
                  formData[
                    "If the client is not on medication, please specify why not?"
                  ]
                }
                onChange={handleInputChange}
                required
                className="w-[96%]  border border-black h-[160px] p-3"
              ></textarea>
            </section>
            <section className="mt-8">
              <h4 className="font-semibold ">Reasons For Referral:</h4>
              <p className="mt-1 ">
                Give a brief description of the individual’s current problems,
                symptoms, and needs. Include any additional reasons for
                referral.
              </p>
              <textarea
                type="text"
                name="Reasons For Referral"
                id=""
                rows={4}
                value={formData["Reasons For Referral"]}
                onChange={handleInputChange}
                required
                className="border-t-0 w-[96%] border-r-0 border-l-0 border-b border-b-black"
              ></textarea>
            </section>
            <div className="w-full flex items-end justify-end mb-[30px]">
              <button
                type="submit"
                className="w-[140px] flex items-start sm:items-center justify-center mt-[32px] h-[40px] text-white rounded-md bg-black/50"
              >
                Submit
              </button>
            </div>
          </form>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default Prp;
