import React from "react";
const TestimonialsBanner = () => {
  return (
    <div id="services">
      <div class="flex  ">
        <div class="w-full min-h-[29rem] bg-[url('https://img.freepik.com/free-photo/team-business-people-collaborating-plan-financial-strategy-doing-teamwork-create-sales-report-laptop-office-employees-working-project-strategy-analyze-career-growth_482257-39530.jpg?w=1380&t=st=1700401978~exp=1700402578~hmac=f3b269c74122a6b4c9ea1542340963923c0fb09e467555e88c6df5937d8cf727')] bg-cover bg-center">
          <div
            class="w-full h-full flex  justify-center items-center 
             bg-black/80 backdrop-brightness-100"
          >
            <p className="text-[4rem] font-bold text-white ">
              Testimonials
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialsBanner;
