import Navbar from "../layout/Navbar";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import one from "../assets/images/testimonials/one.png";
import two from "../assets/images/testimonials/two.png";
import three from "../assets/images/testimonials/three.png";
import Foote from "../components/Home2/Footer";
import TestimonialsBanner from "../components/Home2/TestimonialsBanner";

const Testimonials = () => {
  return (
    <div>
      <ToastContainer
        position="top-center"
        hideProgressBar={true}
        closeOnClick
      />
      <Navbar />
      <div className="w-full  mx-auto  py-20">
        <TestimonialsBanner />
      </div>{" "}
      <div className="w-full max-w-[85rem] h-full py-5 mx-auto px-8 text-black bg-white space-y-16">

      <div className="flex justify-start">
        <img className="h-40  object-cover" src={two} />
      </div>

      <div className="flex justify-end">
        <img className="h-40  object-cover" src={one} />
      </div>

      <div className="flex justify-start">
        <img className="h-[15rem] w-[35rem] object-cover" src={three} />
      </div>
      </div>
      <Foote />2
    </div>
  );
};

export default Testimonials;
