import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { AiOutlineClose } from "react-icons/ai";
const Sidebar = ({ setIsSidebarOpen, isSidebarOpen }) => {
  const location = useLocation();
  const [isFormOpen, setIsFormOpen] = useState(false);

  const sidebarVariant = {
    open: {
      x: 0,
      transition: {
        duration: 0.2,
      },
    },
    closed: {
      x: "100%",
      transition: {
        duration: 0.45,
      },
    },
  };

  const listvariants = {
    open: {
      transition: { staggerChildren: 0.1, delayChildren: 0.2 },
    },
    closed: {
      transition: { staggerChildren: 0.1, staggerDirection: -1 },
    },
  };
  const itemvariants = {
    open: {
      // x: 0,
      x: [0, 20, 0],
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 },
      },
    },
    closed: {
      x: -50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 },
      },
    },
  };

  return (
    <div>
      {" "}
      <motion.aside
        animate={isSidebarOpen ? "open" : "closed"}
        // initial={"closed"}
        variants={sidebarVariant}
        className={`fixed top-0 bottom-0 right-0 w-[100vw] h-[100vh] z-[60] max-w-[400px] backdrop-blur-md bg-white/80 overflow-hidden md:hidden }`}
      >
        <div className="py-[2rem] flex flex-col items-center justify-center w-full h-full">
          <button
            onClick={() => {
              setIsSidebarOpen(!isSidebarOpen);
            }}
            className="flex top-10 absolute justify-end w-full mb-[4rem] pr-[2rem]"
          >
            <AiOutlineClose className="w-6 h-6 text-black" />
          </button>
          <motion.nav>
            <motion.ul className="" variants={listvariants}>
              <motion.li
                variants={itemvariants}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
                // animate={isSidebarOpen && "closed"}
                onClick={() => {
                  setIsSidebarOpen(!isSidebarOpen);
                }}
                className={`relative w-full mb-[1.5rem] ${
                  location.pathname === "/" ? "activeLink" : ""
                }`}
              >
                <a href="/">
                  <motion.a className=" text-[1.3rem] tracking-[2.7px] uppercase">
                    <span className="text-black  mr-[11px]">00</span> Home
                  </motion.a>
                </a>
              </motion.li>
              <motion.li
                variants={itemvariants}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
                // animate={isSidebarOpen && "closed"}
                onClick={() => {
                  setIsSidebarOpen(!isSidebarOpen);
                }}
                className={`relative w-full mb-[1.5rem] ${
                  location.pathname === "/" ? "activeLink" : ""
                }`}
              >
                <a href="/#about">
                  <motion.a className=" text-[1.3rem] tracking-[2.7px] uppercase">
                    <span className="text-black  mr-[11px]">01</span> About
                  </motion.a>
                </a>
              </motion.li>
              <motion.li
                variants={itemvariants}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
                // animate={isSidebarOpen && "closed"}
                onClick={() => {
                  setIsSidebarOpen(!isSidebarOpen);
                }}
                className={`relative w-full mb-[1.5rem] ${
                  location.pathname === "/" ? "activeLink" : ""
                }`}
              >
                <a href="/#services">
                  <motion.a className=" text-[1.3rem] tracking-[2.7px] uppercase">
                    <span className="text-black  mr-[11px]">02</span> Services
                  </motion.a>
                </a>
              </motion.li>
              <motion.li
                variants={itemvariants}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
                // animate={isSidebarOpen && "closed"}
                onClick={() => {
                  setIsSidebarOpen(!isSidebarOpen);
                }}
                className={`relative w-full mb-[1.5rem] ${
                  location.pathname === "/" ? "activeLink" : ""
                }`}
              >
               <a href="/#contact">
                  <motion.a className=" text-[1.3rem] tracking-[2.7px] uppercase">
                    <span className="text-black  mr-[11px]">03</span> Contact
                  </motion.a>
                </a>
              </motion.li>
              <motion.li
                variants={itemvariants}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
                // animate={isSidebarOpen && "closed"}
                onClick={() => {
                  setIsSidebarOpen(!isSidebarOpen);
                }}
                className={`relative w-full mb-[1.5rem] ${
                  location.pathname === "/" ? "activeLink" : ""
                }`}
              >
               <a href="/team">
                  <motion.a className=" text-[1.3rem] tracking-[2.7px] uppercase">
                    <span className="text-black  mr-[11px]">04</span> Team
                  </motion.a>
                </a>
              </motion.li>
              <motion.li
                variants={itemvariants}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
                // animate={isSidebarOpen && "closed"}
                onClick={() => {
                  setIsSidebarOpen(!isSidebarOpen);
                }}
                className={`relative w-full mb-[1.5rem] ${
                  location.pathname === "/" ? "activeLink" : ""
                }`}
              >
               <a href="/testimonials">
                  <motion.a className=" text-[1.3rem] tracking-[2.7px] uppercase">
                    <span className="text-black  mr-[11px]">04</span> Testimonials
                  </motion.a>
                </a>
              </motion.li>
              <motion.li
                variants={itemvariants}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
                className={`relative w-full mb-[1.5rem] ${
                  location.pathname === "/" ? "activeLink" : ""
                }`}
              >
               <a >
                  <motion.a className=" text-[1.3rem] tracking-[2.7px] uppercase">
                    <span className="text-black  mr-[11px]">04</span> 
                    <div className="relative inline-block text-left">
                <div>
                  <button
                    type="button"
                    className="inline-flex justify-center w-full headerLink px-4 py-2 items-center"
                    id="menu-button"
                    aria-expanded="true"
                    aria-haspopup="true"
                    onClick={() => setIsFormOpen(!isFormOpen)}
                  >
                    Forms
                    <svg
                      className="-mr-1 ml-2 h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>

                {isFormOpen && (
                  <div
                    className="origin-top-right z-[99] right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="menu-button"
                    tabIndex="-1"
                  >
                    <div className="py-1" role="none">
                      {/* Dropdown items */}
                      <a
                        href="/job"
                        className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100"
                        role="menuitem"
                        tabIndex="-1"
                        id="menu-item-0"
                      >
                        Job Application
                      </a>
                      <a
                        href="/prp"
                        className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100"
                        role="menuitem"
                        tabIndex="-1"
                        id="menu-item-1"
                      >
                        PRP Referral Form
                      </a>
                    </div>
                  </div>
                )}
              </div>
                  </motion.a>
                </a>
              </motion.li>
              <motion.li
                variants={itemvariants}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
                // animate={isSidebarOpen && "closed"}
                onClick={() => {
                  setIsSidebarOpen(!isSidebarOpen);
                }}
                className={`relative w-full mb-[1.5rem] ${
                  location.pathname === "/" ? "activeLink" : ""
                }`}
              >
<a
            href="appointment"
             className="">
            <button
            class="px-4 py-4 font-medium tracking-wide text-white  capitalize transition-colors duration-300 transform bg-black rounded-lg ">
                    <p className="">
                      Appointment
                    </p>
                  </button>
            </a>
              </motion.li>
            </motion.ul>
            <motion.div className="w-full  h-10 border-t border-t-gray-200"></motion.div>
          </motion.nav>
        </div>
      </motion.aside>
    </div>
  );
};

export default Sidebar;
