export const projects = [
  {
    content: `
    Novum Health Services Incorporated was created as a non-profit 501 3(c) to help make mental health
services accessible and remove the stigma attached to mental health in our community. We access
individual patients on case-by-case diagnosis and we tailor a treatment plan according to unique needs
and preferences. Our services are rendered on evidence-based practice and are provided by
experienced and Licensed personnel to care for our clients.
    `,
    title: "About Us",
  },
  {
    content: `
    Our mission is to offer confidential and holistic services towards promoting the mental health, physical,
    and social concerns of individuals while ensuring optimal integration and sustainability of values within
    the community.
      `,
    title: "Our Mission",
  },
  {
    content: `
    Our vision at Novum Health Services is to create a world where mental health is integral to overall wellbeing, free from stigma and fully accessible. We strive to integrate mental health support into all facets of life, emphasizing personalized care and innovation. Our goal is to foster a community where mental wellness is a fundamental right, ensuring that everyone, regardless of background, has access to the necessary resources for mental, physical, and social health. We are committed to leading the way in transforming mental health care, building a more resilient, informed, and compassionate society
      `,
    title: "Our Vision",
  }
];
