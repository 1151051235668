import Navbar from "../layout/Navbar";
import ceo from "../assets/images/gallery/ceo.jpeg";
import { toast, ToastContainer } from "react-toastify";
import Footer from "../components/Home2/Footer";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";

const Job = () => {
  const [formData, setFormData] = useState({
    Name: "",
    "Home Address": "",
    "City,State,Zip code": "",
    Tel: "",
    "Email address": "",
    "Date Of Application": "",
    "How did you hear about this Position?": "",
    "What days are you available for work?": "",
    "What hours or shifts are you available for work?": "",
    "On what day can you start work if you are hired?": "",
    "Do you have reliable transportation to and from work?": "",
    "Have you ever applied to or worked for Novum Health Services Incorporated before?":
      "",
    "Do you have any friends, relatives, or acquaintances working for Novum Health Services Incorporated?":
      "",
    "If yes, when? (Have you ever applied to or worked for Novum Health Services Incorporated before?)":
      "",
    "If yes, state name and relationship (Do you have any friends, relatives, or acquaintances working for Novum Health Services Incorporated?)":
      "",
    "Are you 18 years of age or older?": "",
    "Are you a U.S citizen or approved to work in the United States": "",
    "What document can you provide as proof of citizenship or legal status?":
      "",
    "Will you consent to a mandatory controlled substance test": "",
    "Have you ever been convicted of a criminal offense (felony or misdemeanor)?":
      "",
    "If yes, please state the nature of the crime(s), when and convicted, and the disposition of the case :":
      "",
    "Please list below the skills and qualifications you possess for the position for which you are applying :":
      "",
    "Salary Desired:": "",
    "Position(s) applying for :": "",
    "High School (Name)": "",
    "High School (Location(City, State))": "",
    "High School (Year Graduated)": "",
    "High School (Degree Earned)": "",
    "College / University (Name)": "",
    "College / University (Location(City, State))": "",
    "College / University (Year Graduated)": "",
    "College / University (Degree Earned)": "",
    "Vocational School (Name)": "",
    "Vocational School (Location(City, State))": "",
    "Vocational School (Year Graduated)": "",
    "Vocational School (Degree Earned)": "",
    "Are you a member of the Armed Services?": "",
    "Employer Name-1": "",
    "Job Title-1": "",
    "Supervisor Name-1": "",
    "Employer Address-1": "",
    "City, State and Zip Code-1": "",
    "Employer Telephone-1": "",
    "Dates Employed-1": "",
    "Reason for leaving-1": "",
    "Employer Name-2": "",
    "Job Title-2": "",
    "Supervisor Name-2": "",
    "Employer Address-2": "",
    "City, State and Zip Code-2": "",
    "Employer Telephone-2": "",
    "Dates Employed-2": "",
    "Reason for leaving-2": "",
    "Employer Name-3": "",
    "Job Title-3": "",
    "Supervisor Name-3": "",
    "Employer Address-3": "",
    "City, State and Zip Code-3": "",
    "Employer Telephone-3": "",
    "Dates Employed-3": "",
    "Reason for leaving-3": "",
    Reference: "",
    "Contact Informaiton": "",
    "Application Signature": "",
    "Date-signature": "",
  });

  const sendMessage = async (e) => {
    e.preventDefault();

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    };

    console.log(formData);

    try {
      const res = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/email/job`,
        options
      );
      if (!res.ok) {
        throw Error("error response");
      }
      toast.success("Job application submitted");
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
      throw error;
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  return (
    <>
      <Navbar />
      <ToastContainer
        position="top-center"
        hideProgressBar={true}
        closeOnClick
      />
      <div className="w-full font-Poppins pt-[100px]">
        <h3 className="underline center text-center text-3xl lg:text-5xl font-bold">
          JOB APPLICATION
        </h3>
        <h3 className="font-bold text-xl mt-4 text-center ">
          Novum Health Services Incorporated
        </h3>
        <h5 className="font-semibold text-center text-xs tracking-wider leading-7 mt-4 ">
          1404 Crain Hwy S,Glen Burnie, Maryland 21061 <br />
          443-698-8250
        </h5>
        <section className="mt-14 leading-normal w-full lg:max-w-[1280px] mx-auto px-[4%] sm:px-[50px]">
          <p className="">
            Novum Health Services Incorporated is an equal-opportunity employer.
            This application will not limit or exclude any occupant from
            consideration for employment on a basis prohibited by local, state,
            or federal law. Should an applicant need a reasonable accommodation
            in the application process, he or she should contact a company
            representative.
          </p>

          <form
            action=""
            className="mt-8 w-full tracking-wide"
            onSubmit={sendMessage}
          >
            <h3 className="font-semibold italic">
              Please fill out all of the sections below:
            </h3>
            <article className="flex flex-col gap-3 mt-3">
              <div className="flex sm:items-end flex-col sm:flex-row  gap-[10px] justify-between mt-[3px]">
                <h4 className="relative font-semibold bottom-[-7px]">Name:</h4>
                <input
                  type="text"
                  name="Name"
                  id=""
                  value={formData.Name}
                  onChange={handleInputChange}
                  required
                  className="border-t-0 border-r-0 w-[70%] border-l-0 border-b border-b-black"
                />
              </div>
              <div className="flex sm:items-end flex-col sm:flex-row  gap-[10px] justify-between mt-[3px]">
                <h4 className="relative font-semibold bottom-[-7px]">
                  Home Address:
                </h4>
                <input
                  type="text"
                  onChange={handleInputChange}
                  required
                  name="Home Address"
                  id=""
                  value={formData["Home Address"]}
                  className="border-t-0 w-[70%] border-r-0 border-l-0 border-b border-b-black"
                />
              </div>
              <div className="flex sm:items-end flex-col sm:flex-row  gap-[10px] justify-between mt-[3px]">
                <h4 className="relative font-semibold bottom-[-7px]">
                  City, State and Zip Code:
                </h4>
                <input
                  required
                  onChange={handleInputChange}
                  type="text"
                  name="City,State,Zip code"
                  id=""
                  value={formData["City,State,Zip code"]}
                  className="border-t-0 border-r-0 w-[70%] border-l-0 border-b border-b-black"
                />
              </div>
              <div className="flex sm:items-end flex-col sm:flex-row  gap-[10px] justify-between mt-[3px]">
                <h4 className="relative font-semibold bottom-[-7px]">
                  Telephone Number:
                </h4>
                <input
                  type="text"
                  name="Tel"
                  required
                  value={formData.Tel}
                  onChange={handleInputChange}
                  id=""
                  className="border-t-0 w-[70%] border-r-0 border-l-0 border-b border-b-black"
                />
              </div>
              <div className="flex sm:items-end flex-col sm:flex-row  gap-[10px] justify-between mt-[3px]">
                <h4 className="relative font-semibold bottom-[-7px]">
                  Email Address:
                </h4>
                <input
                  name="Email address"
                  type="email"
                  id=""
                  value={formData["Email address"]}
                  onChange={handleInputChange}
                  required
                  className="border-t-0 w-[70%] border-r-0 border-l-0 border-b border-b-black"
                />
              </div>
              <div className="flex sm:items-end flex-col sm:flex-row  gap-[10px] justify-between mt-[3px]">
                <h4 className="relative font-semibold bottom-[-7px]">
                  Date of Application:
                </h4>
                <input
                  type="text"
                  name="Date Of Application"
                  id=""
                  value={formData["Date Of Application"]}
                  onChange={handleInputChange}
                  required
                  className="border-t-0 w-[70%] border-r-0 border-l-0 border-b border-b-black"
                />
              </div>
            </article>
            <section className="mt-[72px]">
              <h3 className="font-semibold underline">Employment Position</h3>
              <div className="flex sm:items-end flex-col sm:flex-row  gap-[10px] justify-between mt-[3px]">
                <h5 className="font-semibold italic mt-3">
                  Position(s) applying for :
                </h5>
                <input
                  type="text"
                  value={formData["Position(s) applying for :"]}
                  onChange={handleInputChange}
                  required
                  name="Position(s) applying for :"
                  id=""
                  className="border-t-0 border-r-0 border-l-0 border-b border-b-black w-full"
                />
              </div>
              <article className="flex flex-col gap-3 mt-6">
                <div className="flex sm:items-end flex-col sm:flex-row  gap-[10px] justify-between mt-[3px]">
                  <h4 className="relative font-medium bottom-[-7px]">
                    How did you hear about this Position?
                  </h4>
                  <input
                    type="text"
                    value={formData["How did you hear about this Position?"]}
                    onChange={handleInputChange}
                    required
                    name="How did you hear about this Position?"
                    id=""
                    className="border-t-0 border-r-0 border-l-0 border-b border-b-black w-full"
                  />
                </div>
                <div className="flex sm:items-end flex-col sm:flex-row  gap-[10px] justify-between mt-[3px]">
                  <h4 className="relative font-medium bottom-[-7px]">
                    What days are you available for work?
                  </h4>
                  <input
                    value={formData["What days are you available for work?"]}
                    onChange={handleInputChange}
                    required
                    type="text"
                    name="What days are you available for work?"
                    id=""
                    className="border-t-0 border-r-0 border-l-0 border-b border-b-black w-full"
                  />
                </div>
                <div className="flex sm:items-end flex-col sm:flex-row  gap-[10px] justify-between mt-[3px]">
                  <h4 className="relative font-medium bottom-[-7px]">
                    What hours or shifts are you available for work?
                  </h4>
                  <input
                    value={
                      formData[
                        "What hours or shifts are you available for work?"
                      ]
                    }
                    onChange={handleInputChange}
                    required
                    type="text"
                    name="What hours or shifts are you available for work?"
                    id=""
                    className="border-t-0 border-r-0 border-l-0 border-b border-b-black w-full"
                  />
                </div>
                <div className="flex sm:items-end flex-col sm:flex-row  gap-[10px] justify-between mt-[3px]">
                  <h4 className="relative font-medium bottom-[-7px]">
                    On what day can you start work if you are hired?
                  </h4>
                  <input
                    value={
                      formData[
                        "On what day can you start work if you are hired?"
                      ]
                    }
                    onChange={handleInputChange}
                    required
                    type="text"
                    name="On what day can you start work if you are hired?"
                    id=""
                    className="border-t-0 border-r-0 border-l-0 border-b border-b-black w-full"
                  />
                </div>
                <div className="flex sm:items-end flex-col sm:flex-row  gap-[10px] justify-between mt-[3px]">
                  <h4 className="relative font-medium bottom-[-7px]">
                    Do you have reliable transportation to and from work?
                  </h4>
                  <input
                    value={
                      formData[
                        "Do you have reliable transportation to and from work?"
                      ]
                    }
                    onChange={handleInputChange}
                    required
                    type="text"
                    name="Do you have reliable transportation to and from work?"
                    id=""
                    className="border-t-0 border-r-0 border-l-0 border-b border-b-black w-full"
                  />
                </div>
                <div className="flex sm:items-end flex-col sm:flex-row  gap-[10px] justify-between mt-[3px]">
                  <h4 className="relative font-medium bottom-[-7px]">
                    Salary Desired:
                  </h4>
                  <input
                    type="text"
                    value={formData["Salary Desired:"]}
                    onChange={handleInputChange}
                    required
                    name="Salary Desired:"
                    id=""
                    className="border-t-0 border-r-0 border-l-0 border-b border-b-black w-full"
                  />
                </div>
              </article>
            </section>
            <article className="mt-10">
              <h5 className="font-semibold underline mt-3">
                Personal Information
              </h5>
              <div className="mt-3">
                <h4 className="relative font-medium bottom-[-7px] leading-7">
                  Have you ever applied to or worked for Novum Health Services
                  Incorporated before?
                  <b className="ml-[10px] flex gap-2">
                    <label htmlFor="">
                      <input
                        className="mr-2"
                        type="radio"
                        onChange={handleInputChange}
                        name="Have you ever applied to or worked for Novum Health Services Incorporated before?"
                        value="Yes"
                        required="required"
                        id=""
                      />
                      Yes
                    </label>
                    <label htmlFor="">
                      <input
                        className="mr-2"
                        onChange={handleInputChange}
                        name="Have you ever applied to or worked for Novum Health Services Incorporated before?"
                        type="radio"
                        required="required"
                        value="No"
                        id=""
                      />
                      No
                    </label>
                  </b>{" "}
                  <br /> If yes, when?
                </h4>
                <div className="flex flex-col gap-[16px]">
                  <textarea
                    type="text"
                    name="If yes, when? (Have you ever applied to or worked for Novum Health Services Incorporated before?)"
                    rows={2}
                    onChange={handleInputChange}
                    value={
                      formData[
                        "If yes, when? (Have you ever applied to or worked for Novum Health Services Incorporated before?)"
                      ]
                    }
                    id=""
                    className="border-t-0 border-r-0 border-l-0 border-b border-b-black w-full"
                  ></textarea>
                </div>
              </div>
            </article>
            <article className="mt-10">
              <div className="mt-5">
                <h4 className="relative font-medium bottom-[-7px] leading-7">
                  Do you have any friends, relatives, or acquaintances working
                  for Novum Health Services Incorporated?
                  <b className="ml-[10px] flex gap-2">
                    <label htmlFor="">
                      <input
                        className="mr-2"
                        type="radio"
                        required
                        onChange={handleInputChange}
                        name="Do you have any friends, relatives, or acquaintances working for Novum Health Services Incorporated?"
                        value="Yes"
                        id=""
                      />
                      Yes
                    </label>
                    <label htmlFor="">
                      <input
                        className="mr-2"
                        required
                        type="radio"
                        onChange={handleInputChange}
                        name="Do you have any friends, relatives, or acquaintances working for Novum Health Services Incorporated?"
                        value="No"
                        id=""
                      />
                      No
                    </label>
                  </b>{" "}
                  <br /> If yes, state name and relationship
                </h4>
                <div className="flex flex-col gap-[16px]">
                  <textarea
                    type="text"
                    name="If yes, state name and relationship (Do you have any friends, relatives, or acquaintances working for Novum Health Services Incorporated?)"
                    id=""
                    onChange={handleInputChange}
                    value={
                      formData[
                        "If yes, state name and relationship (Do you have any friends, relatives, or acquaintances working for Novum Health Services Incorporated?)"
                      ]
                    }
                    className="border-t-0 border-r-0 border-l-0 border-b border-b-black w-full"
                  ></textarea>
                </div>
              </div>
            </article>
            <article className="mt-10">
              <div className="mt-5">
                <h4 className="relative font-medium bottom-[-7px] leading-7">
                  Are you 18 years of age or older?
                  <b className="ml-[10px] flex gap-2">
                    <label htmlFor="">
                      <input
                        className="mr-2"
                        type="radio"
                        name="Are you 18 years of age or older?"
                        value="Yes"
                        onChange={handleInputChange}
                        required
                        id=""
                      />
                      Yes
                    </label>
                    <label htmlFor="">
                      <input
                        className="mr-2"
                        name="Are you 18 years of age or older?"
                        type="radio"
                        value="No"
                        onChange={handleInputChange}
                        required
                        id=""
                      />
                      No
                    </label>
                  </b>{" "}
                </h4>
                <h4 className="relative font-medium bottom-[-7px] leading-7">
                  Are you a U.S citizen or approved to work in the United States
                  <b className="ml-[10px] flex gap-2">
                    <label htmlFor="">
                      <input
                        className="mr-2"
                        type="radio"
                        name="Are you a U.S citizen or approved to work in the United States"
                        value="Yes"
                        onChange={handleInputChange}
                        required
                        id=""
                      />
                      Yes
                    </label>
                    <label htmlFor="">
                      <input
                        className="mr-2"
                        name="Are you a U.S citizen or approved to work in the United States"
                        type="radio"
                        value="No"
                        onChange={handleInputChange}
                        required
                        id=""
                      />
                      No
                    </label>
                  </b>{" "}
                </h4>

                <div className="flex flex-col gap-[16px]">
                  <h4 className="relative font-medium bottom-[-7px] leading-7">
                    What document can you provide as proof of citizenship or
                    legal status?
                  </h4>
                  <textarea
                    type="text"
                    name="What document can you provide as proof of citizenship or legal status?"
                    id=""
                    onChange={handleInputChange}
                    required
                    value={
                      formData[
                        "What document can you provide as proof of citizenship or legal status?"
                      ]
                    }
                    className="border-t-0 border-r-0 border-l-0 border-b border-b-black w-full"
                  ></textarea>
                </div>
              </div>
            </article>
            <article className="mt-10">
              <div className="mt-5">
                <h4 className="relative font-medium bottom-[-7px] leading-7">
                  Will you consent to a mandatory controlled substance test
                  <b className="ml-[10px] flex gap-2">
                    <label htmlFor="">
                      <input
                        className="mr-2"
                        type="radio"
                        name="Will you consent to a mandatory controlled substance test"
                        value="Yes"
                        onChange={handleInputChange}
                        required
                        id=""
                      />
                      Yes
                    </label>
                    <label htmlFor="">
                      <input
                        className="mr-2"
                        name="Will you consent to a mandatory controlled substance test"
                        type="radio"
                        value="No"
                        onChange={handleInputChange}
                        required
                        id=""
                      />
                      No
                    </label>
                  </b>{" "}
                </h4>
                <h4 className="relative font-medium bottom-[-7px] leading-7">
                  Have you ever been convicted of a criminal offense (felony or
                  misdemeanor)?
                  <b className="ml-[10px] flex gap-2">
                    <label htmlFor="">
                      <input
                        className="mr-2"
                        type="radio"
                        name="Have you ever been convicted of a criminal offense (felony or  misdemeanor)?"
                        value="Yes"
                        onChange={handleInputChange}
                        required
                        id=""
                      />
                      Yes
                    </label>
                    <label htmlFor="">
                      <input
                        className="mr-2"
                        type="radio"
                        name="Have you ever been convicted of a criminal offense (felony or  misdemeanor)?"
                        value="No"
                        onChange={handleInputChange}
                        required
                        id=""
                      />
                      No
                    </label>
                  </b>{" "}
                </h4>
                <div className="flex flex-col gap-[16px]">
                  <h4 className="relative font-medium bottom-[-7px] leading-7">
                    If yes, please state the nature of the crime(s), when and
                    where convicted, and the disposition of the case :
                  </h4>
                  <textarea
                    type="text"
                    name="If yes, please state the nature of the crime(s), when and convicted, and the disposition of the case :"
                    id=""
                    onChange={handleInputChange}
                    value={
                      formData[
                        "If yes, please state the nature of the crime(s), when and convicted, and the disposition of the case :"
                      ]
                    }
                    className="border-t-0 border-r-0 border-l-0 border-b border-b-black w-full"
                  ></textarea>
                </div>
              </div>
            </article>
            <article className="mt-10">
              <p className="italic">
                (Note: No applicant will be denied employment solely on the
                grounds of conviction of a criminal offense. The date of the
                offense, the nature of the offense, including any significant
                details that affect the description of the event and the
                surrounding circumstances, and the relevance of the offense to
                the position(s) applied for may, however, be considered.)
              </p>
            </article>
            <article className="mt-10">
              <h5 className="font-semibold underline mt-3">
                Job Skills/Qualifications
              </h5>
              <div className="mt-3">
                <h4 className="relative font-medium bottom-[-7px] leading-7">
                  Please list below the skills and qualifications you possess
                  for the position for which you are applying :
                </h4>
                <div className="flex flex-col gap-[16px]">
                  <textarea
                    type="text"
                    onChange={handleInputChange}
                    required
                    value={
                      formData[
                        "Please list below the skills and qualifications you possess for the position for which you are applying :"
                      ]
                    }
                    name="Please list below the skills and qualifications you possess for the position for which you are applying :"
                    id=""
                    className="border-t-0 border-r-0 border-l-0 border-b border-b-black w-full"
                  />
                </div>

                <p className="italic mt-[20px]">
                  (Note: No applicant will be denied employment solely on the
                  grounds of conviction of a criminal offense. The date of the
                  offense, the nature of the offense, including any significant
                  details that affect the description of the event and the
                  surrounding circumstances, and the relevance of the offense to
                  the position(s) applied for may, however, be considered.)
                </p>
              </div>
            </article>

            <article className="mt-10">
              <h5 className="font-semibold underline mt-3">
                Job Skills/Qualifications
              </h5>
              <h4 className="relative font-medium mt-[10px] ">
                Do you have reliable transportation to and from work?
              </h4>
              <div className="flex flex-col gap-[16px]">
                <textarea
                  onChange={handleInputChange}
                  required
                  value={
                    formData[
                      "Do you have reliable transportation to and from work?"
                    ]
                  }
                  rows={2}
                  name="Do you have reliable transportation to and from work?"
                  id=""
                  className="border-t-0 border-r-0 border-l-0 border-b border-b-black w-full"
                ></textarea>
              </div>
              <p className="italic mt-3">
                (Note: Novum Health Services Incorporated complies with the ADA
                and considers reasonable accommodation measures that may be
                necessary for eligible applicants/employees to perform essential
                functions.)
              </p>
            </article>

            <article className="mt-10">
              <h5 className="font-semibold underline mt-3">
                Education and Training
              </h5>
              <div className="mt-12">
                <h3 className="font-semibold ">High School</h3>
                <div className="flex sm:items-end mt-2 flex-col sm:flex-row  gap-[10px] justify-between ">
                  <h4 className="relative font-medium bottom-[-7px]">Name:</h4>
                  <input
                    type="text"
                    name="High School (Name)"
                    id=""
                    onChange={handleInputChange}
                    required
                    value={formData["High School (Name)"]}
                    className="border-t-0 border-r-0 border-l-0 border-b border-b-black w-full"
                  />
                </div>
                <div className="flex sm:items-end flex-col sm:flex-row  gap-[10px] justify-between  mt-2">
                  <h4 className="relative font-medium bottom-[-7px]">
                    Location(City, State)
                  </h4>
                  <input
                    type="text"
                    name="High School (Location(City, State))"
                    id=""
                    onChange={handleInputChange}
                    required
                    value={formData["High School (Location(City, State))"]}
                    className="border-t-0 border-r-0 border-l-0 border-b border-b-black w-full"
                  />
                </div>
                <div className="flex sm:items-end flex-col sm:flex-row  gap-[10px] justify-between  mt-2">
                  <h4 className="relative font-medium bottom-[-7px]">
                    Year Graduated:
                  </h4>
                  <input
                    type="text"
                    name="High School (Year Graduated)"
                    id=""
                    onChange={handleInputChange}
                    required
                    value={formData["High School (Year Graduated)"]}
                    className="border-t-0 border-r-0 border-l-0 border-b border-b-black w-full"
                  />
                </div>
                <div className="flex sm:items-end flex-col sm:flex-row  gap-[10px] justify-between  mt-2">
                  <h4 className="relative font-medium bottom-[-7px]">
                    Degree Earned
                  </h4>
                  <input
                    type="text"
                    name="High School (Degree Earned)"
                    id=""
                    onChange={handleInputChange}
                    required
                    value={formData["High School (Degree Earned)"]}
                    className="border-t-0 border-r-0 border-l-0 border-b border-b-black w-full"
                  />
                </div>
              </div>
              <div className="mt-12">
                <h3 className="font-semibold ">College / University</h3>
                <div className="flex sm:items-end flex-col sm:flex-row  gap-[10px] justify-between  mt-2">
                  <h4 className="relative font-medium bottom-[-7px]">Name:</h4>
                  <input
                    type="text"
                    name="College / University (Name)"
                    id=""
                    onChange={handleInputChange}
                    required
                    value={formData["College / University (Name)"]}
                    className="border-t-0 border-r-0 border-l-0 border-b border-b-black w-full"
                  />
                </div>
                <div className="flex sm:items-end flex-col sm:flex-row  gap-[10px] justify-between  mt-2">
                  <h4 className="relative font-medium bottom-[-7px]">
                    Location(City, State)
                  </h4>
                  <input
                    type="text"
                    onChange={handleInputChange}
                    required
                    value={
                      formData["College / University (Location(City, State))"]
                    }
                    name="College / University (Location(City, State))"
                    id=""
                    className="border-t-0 border-r-0 border-l-0 border-b border-b-black w-full"
                  />
                </div>
                <div className="flex sm:items-end flex-col sm:flex-row  gap-[10px] justify-between  mt-2">
                  <h4 className="relative font-medium bottom-[-7px]">
                    Year Graduated:
                  </h4>
                  <input
                    type="text"
                    name="College / University (Year Graduated)"
                    onChange={handleInputChange}
                    required
                    value={formData["College / University (Year Graduated)"]}
                    id=""
                    className="border-t-0 border-r-0 border-l-0 border-b border-b-black w-full"
                  />
                </div>
                <div className="flex sm:items-end flex-col sm:flex-row  gap-[10px] justify-between  mt-2">
                  <h4 className="relative font-medium bottom-[-7px]">
                    Degree Earned
                  </h4>
                  <input
                    type="text"
                    name="College / University (Degree Earned)"
                    onChange={handleInputChange}
                    required
                    value={formData["College / University (Degree Earned)"]}
                    id=""
                    className="border-t-0 border-r-0 border-l-0 border-b border-b-black w-full"
                  />
                </div>
              </div>
              <div className="mt-12">
                <h3 className="font-semibold ">
                  Vocational School / Specialized Training
                </h3>
                <div className="flex sm:items-end flex-col sm:flex-row  gap-[10px] justify-between  mt-2">
                  <h4 className="relative font-medium bottom-[-7px]">Name:</h4>
                  <input
                    type="text"
                    name="Vocational School (Name)"
                    onChange={handleInputChange}
                    required
                    value={formData["Vocational School (Name)"]}
                    id=""
                    className="border-t-0 border-r-0 border-l-0 border-b border-b-black w-full"
                  />
                </div>
                <div className="flex sm:items-end flex-col sm:flex-row  gap-[10px] justify-between  mt-2">
                  <h4 className="relative font-medium bottom-[-7px]">
                    Location(City, State)
                  </h4>
                  <input
                    type="text"
                    name="Vocational School (Location(City, State))"
                    id=""
                    onChange={handleInputChange}
                    required
                    value={
                      formData["Vocational School (Location(City, State))"]
                    }
                    className="border-t-0 border-r-0 border-l-0 border-b border-b-black w-full"
                  />
                </div>
                <div className="flex sm:items-end flex-col sm:flex-row  gap-[10px] justify-between  mt-2">
                  <h4 className="relative font-medium bottom-[-7px]">
                    Year Graduated:
                  </h4>
                  <input
                    type="text"
                    name="Vocational School (Year Graduated)"
                    onChange={handleInputChange}
                    required
                    value={formData["Vocational School (Year Graduated)"]}
                    id=""
                    className="border-t-0 border-r-0 border-l-0 border-b border-b-black w-full"
                  />
                </div>
                <div className="flex sm:items-end flex-col sm:flex-row  gap-[10px] justify-between  mt-2">
                  <h4 className="relative font-medium bottom-[-7px]">
                    Degree Earned
                  </h4>
                  <input
                    type="text"
                    name="Vocational School (Degree Earned)"
                    onChange={handleInputChange}
                    required
                    value={formData["Vocational School (Degree Earned)"]}
                    id=""
                    className="border-t-0 border-r-0 border-l-0 border-b border-b-black w-full"
                  />
                </div>
              </div>
            </article>
            <article className="mt-10">
              <h3 className="font-semibold">Military:</h3>
              <div className="flex sm:items-end flex-col sm:flex-row  gap-[10px] justify-between mt-[3px]">
                <h4 className="relative font-semibold bottom-[-7px]">
                  Are you a member of the Armed Services?
                </h4>
                <input
                  type="text"
                  name="Are you a member of the Armed Services?"
                  id=""
                  onChange={handleInputChange}
                  required
                  value={formData["Are you a member of the Armed Services?"]}
                  className="border-t-0 border-r-0 w-full border-l-0 border-b border-b-black"
                />
              </div>
            </article>
            <section className="mt-[72px]">
              <h3 className="font-semibold underline">Previous Employment</h3>

              <article className="flex flex-col gap-3 mt-6">
                <div className="flex sm:items-end flex-col sm:flex-row  gap-[10px] justify-between mt-[3px]">
                  <h5 className="font-semibold  mt-3">Employer Name:</h5>
                  <input
                    type="text"
                    name="Employer Name-1"
                    onChange={handleInputChange}
                    required
                    value={formData["Employer Name-1"]}
                    id=""
                    className="border-t-0 border-r-0 border-l-0 border-b border-b-black w-full"
                  />
                </div>
                <div className="flex sm:items-end flex-col sm:flex-row  gap-[10px] justify-between mt-[3px]">
                  <h4 className="relative font-medium bottom-[-7px]">
                    Job Title:
                  </h4>
                  <input
                    type="text"
                    name="Job Title-1"
                    onChange={handleInputChange}
                    required
                    value={formData["Job Title-1"]}
                    id=""
                    className="border-t-0 border-r-0 border-l-0 border-b border-b-black w-full"
                  />
                </div>
                <div className="flex sm:items-end flex-col sm:flex-row  gap-[10px] justify-between mt-[3px]">
                  <h4 className="relative font-medium bottom-[-7px]">
                    Supervisor Name
                  </h4>
                  <input
                    type="text"
                    name="Supervisor Name-1"
                    onChange={handleInputChange}
                    required
                    value={formData["Supervisor Name-1"]}
                    id=""
                    className="border-t-0 border-r-0 border-l-0 border-b border-b-black w-full"
                  />
                </div>
                <div className="flex sm:items-end flex-col sm:flex-row  gap-[10px] justify-between mt-[3px]">
                  <h4 className="relative font-medium bottom-[-7px]">
                    Employer Address:
                  </h4>
                  <input
                    type="text"
                    name="Employer Address-1"
                    onChange={handleInputChange}
                    required
                    value={formData["Employer Address-1"]}
                    id=""
                    className="border-t-0 border-r-0 border-l-0 border-b border-b-black w-full"
                  />
                </div>
                <div className="flex sm:items-end flex-col sm:flex-row  gap-[10px] justify-between mt-[3px]">
                  <h4 className="relative font-medium bottom-[-7px]">
                    City, State and Zip Code:
                  </h4>
                  <input
                    type="text"
                    name="City, State and Zip Code-1"
                    onChange={handleInputChange}
                    required
                    value={formData["City, State and Zip Code-1"]}
                    id=""
                    className="border-t-0 border-r-0 border-l-0 border-b border-b-black w-full"
                  />
                </div>
                <div className="flex sm:items-end flex-col sm:flex-row  gap-[10px] justify-between mt-[3px]">
                  <h4 className="relative font-medium bottom-[-7px] whitespace-nowrap">
                    Employer Telephone:
                  </h4>
                  <input
                    type="text"
                    name="Employer Telephone-1"
                    onChange={handleInputChange}
                    required
                    value={formData["Employer Telephone-1"]}
                    id=""
                    className="border-t-0 border-r-0 border-l-0 border-b border-b-black w-full"
                  />
                </div>
                <div className="flex sm:items-end flex-col sm:flex-row  gap-[10px] justify-between mt-[3px]">
                  <h4 className="relative font-medium bottom-[-7px]">
                    Dates Employed:
                  </h4>
                  <input
                    type="text"
                    name="Dates Employed-1"
                    onChange={handleInputChange}
                    required
                    value={formData["Dates Employed-1"]}
                    id=""
                    className="border-t-0 border-r-0 border-l-0 border-b border-b-black w-full"
                  />
                </div>
                <div className="flex sm:items-end flex-col sm:flex-row  gap-[10px] justify-between mt-[3px]">
                  <h4 className="relative font-medium bottom-[-7px]">
                    Reason for leaving:
                  </h4>
                  <input
                    type="text"
                    name="Reason for leaving-1"
                    onChange={handleInputChange}
                    required
                    value={formData["Reason for leaving-1"]}
                    id=""
                    className="border-t-0 border-r-0 border-l-0 border-b border-b-black w-full"
                  />
                </div>
              </article>
              <article className="flex flex-col gap-3 mt-6">
                <div className="flex sm:items-end flex-col sm:flex-row  gap-[10px] justify-between mt-[3px]">
                  <h5 className="font-semibold  mt-3">Employer Name:</h5>
                  <input
                    type="text"
                    name="Employer Name-2"
                    onChange={handleInputChange}
                    required
                    value={formData["Employer Name-2"]}
                    id=""
                    className="border-t-0 border-r-0 border-l-0 border-b border-b-black w-full"
                  />
                </div>
                <div className="flex sm:items-end flex-col sm:flex-row  gap-[10px] justify-between mt-[3px]">
                  <h4 className="relative font-medium bottom-[-7px]">
                    Job Title:
                  </h4>
                  <input
                    type="text"
                    name="Job Title-2"
                    onChange={handleInputChange}
                    required
                    value={formData["Job Title-2"]}
                    id=""
                    className="border-t-0 border-r-0 border-l-0 border-b border-b-black w-full"
                  />
                </div>
                <div className="flex sm:items-end flex-col sm:flex-row  gap-[10px] justify-between mt-[3px]">
                  <h4 className="relative font-medium bottom-[-7px]">
                    Supervisor Name
                  </h4>
                  <input
                    type="text"
                    name="Supervisor Name-2"
                    onChange={handleInputChange}
                    required
                    value={formData["Supervisor Name-2"]}
                    id=""
                    className="border-t-0 border-r-0 border-l-0 border-b border-b-black w-full"
                  />
                </div>
                <div className="flex sm:items-end flex-col sm:flex-row  gap-[10px] justify-between mt-[3px]">
                  <h4 className="relative font-medium bottom-[-7px]">
                    Employer Address:
                  </h4>
                  <input
                    type="text"
                    name="Employer Address-2"
                    onChange={handleInputChange}
                    required
                    value={formData["Employer Address-2"]}
                    id=""
                    className="border-t-0 border-r-0 border-l-0 border-b border-b-black w-full"
                  />
                </div>
                <div className="flex sm:items-end flex-col sm:flex-row  gap-[10px] justify-between mt-[3px]">
                  <h4 className="relative font-medium bottom-[-7px]">
                    City, State and Zip Code:
                  </h4>
                  <input
                    type="text"
                    name="City, State and Zip Code-2"
                    onChange={handleInputChange}
                    required
                    value={formData["City, State and Zip Code-2"]}
                    id=""
                    className="border-t-0 border-r-0 border-l-0 border-b border-b-black w-full"
                  />
                </div>
                <div className="flex sm:items-end flex-col sm:flex-row  gap-[10px] justify-between mt-[3px]">
                  <h4 className="relative font-medium bottom-[-7px] whitespace-nowrap">
                    Employer Telephone:
                  </h4>
                  <input
                    type="text"
                    name="Employer Telephone-2"
                    onChange={handleInputChange}
                    required
                    value={formData["Employer Telephone-2"]}
                    id=""
                    className="border-t-0 border-r-0 border-l-0 border-b border-b-black w-full"
                  />
                </div>
                <div className="flex sm:items-end flex-col sm:flex-row  gap-[10px] justify-between mt-[3px]">
                  <h4 className="relative font-medium bottom-[-7px]">
                    Dates Employed:
                  </h4>
                  <input
                    type="text"
                    name="Dates Employed-2"
                    onChange={handleInputChange}
                    required
                    value={formData["Dates Employed-2"]}
                    id=""
                    className="border-t-0 border-r-0 border-l-0 border-b border-b-black w-full"
                  />
                </div>
                <div className="flex sm:items-end flex-col sm:flex-row  gap-[10px] justify-between mt-[3px]">
                  <h4 className="relative font-medium bottom-[-7px]">
                    Reason for leaving:
                  </h4>
                  <input
                    type="text"
                    name="Reason for leaving-2"
                    onChange={handleInputChange}
                    required
                    value={formData["Reason for leaving-2"]}
                    id=""
                    className="border-t-0 border-r-0 border-l-0 border-b border-b-black w-full"
                  />
                </div>
              </article>
              <article className="flex flex-col gap-3 mt-6">
                <div className="flex sm:items-end flex-col sm:flex-row  gap-[10px] justify-between mt-[3px]">
                  <h5 className="font-semibold  mt-3">Employer Name:</h5>
                  <input
                    type="text"
                    name="Employer Name-3"
                    onChange={handleInputChange}
                    required
                    value={formData["Employer Name-3"]}
                    id=""
                    className="border-t-0 border-r-0 border-l-0 border-b border-b-black w-full"
                  />
                </div>
                <div className="flex sm:items-end flex-col sm:flex-row  gap-[10px] justify-between mt-[3px]">
                  <h4 className="relative font-medium bottom-[-7px]">
                    Job Title:
                  </h4>
                  <input
                    type="text"
                    name="Job Title-3"
                    onChange={handleInputChange}
                    required
                    value={formData["Job Title-3"]}
                    id=""
                    className="border-t-0 border-r-0 border-l-0 border-b border-b-black w-full"
                  />
                </div>
                <div className="flex sm:items-end flex-col sm:flex-row  gap-[10px] justify-between mt-[3px]">
                  <h4 className="relative font-medium bottom-[-7px]">
                    Supervisor Name
                  </h4>
                  <input
                    type="text"
                    name="Supervisor Name-3"
                    onChange={handleInputChange}
                    required
                    value={formData["Supervisor Name-3"]}
                    id=""
                    className="border-t-0 border-r-0 border-l-0 border-b border-b-black w-full"
                  />
                </div>
                <div className="flex sm:items-end flex-col sm:flex-row  gap-[10px] justify-between mt-[3px]">
                  <h4 className="relative font-medium bottom-[-7px]">
                    Employer Address:
                  </h4>
                  <input
                    type="text"
                    name="Employer Address-3"
                    onChange={handleInputChange}
                    required
                    value={formData["Employer Address-3"]}
                    id=""
                    className="border-t-0 border-r-0 border-l-0 border-b border-b-black w-full"
                  />
                </div>
                <div className="flex sm:items-end flex-col sm:flex-row  gap-[10px] justify-between mt-[3px]">
                  <h4 className="relative font-medium bottom-[-7px]">
                    City, State and Zip Code:
                  </h4>
                  <input
                    type="text"
                    name="City, State and Zip Code-3"
                    onChange={handleInputChange}
                    required
                    value={formData["City, State and Zip Code-3"]}
                    id=""
                    className="border-t-0 border-r-0 border-l-0 border-b border-b-black w-full"
                  />
                </div>
                <div className="flex sm:items-end flex-col sm:flex-row  gap-[10px] justify-between mt-[3px]">
                  <h4 className="relative font-medium bottom-[-7px] whitespace-nowrap">
                    Employer Telephone:
                  </h4>
                  <input
                    type="text"
                    name="Employer Telephone-3"
                    onChange={handleInputChange}
                    required
                    value={formData["Employer Telephone-3"]}
                    id=""
                    className="border-t-0 border-r-0 border-l-0 border-b border-b-black w-full"
                  />
                </div>
                <div className="flex sm:items-end flex-col sm:flex-row  gap-[10px] justify-between mt-[3px]">
                  <h4 className="relative font-medium bottom-[-7px]">
                    Dates Employed:
                  </h4>
                  <input
                    type="text"
                    name="Dates Employed-3"
                    onChange={handleInputChange}
                    required
                    value={formData["Dates Employed-3"]}
                    id=""
                    className="border-t-0 border-r-0 border-l-0 border-b border-b-black w-full"
                  />
                </div>
                <div className="flex sm:items-end flex-col sm:flex-row  gap-[10px] justify-between mt-[3px]">
                  <h4 className="relative font-medium bottom-[-7px]">
                    Reason for leaving:
                  </h4>
                  <input
                    type="text"
                    name="Reason for leaving-3"
                    onChange={handleInputChange}
                    required
                    value={formData["Reason for leaving-3"]}
                    id=""
                    className="border-t-0 border-r-0 border-l-0 border-b border-b-black w-full"
                  />
                </div>
              </article>
            </section>

            <section className="mt-8">
              <h4 className="font-semibold italic underline">References</h4>
              <p className="mt-4">
                Please provide 2 personal and professional reference(s) below:
              </p>
              <div className="flex sm:items-end flex-col sm:flex-row  gap-[10px] mt-3 justify-between ">
                <h4 className="relative font-medium bottom-[-7px] ">
                  Reference:
                </h4>
                <input
                  type="text"
                  name="Reference"
                  id=""
                  onChange={handleInputChange}
                  required
                  value={formData.Reference}
                  className="border-t-0 border-r-0 border-l-0 border-b border-b-black w-full"
                />
              </div>
              <div className="flex sm:items-end flex-col sm:flex-row  gap-[10px] justify-between mt-6">
                <h4 className="relative font-medium bottom-[-7px]">
                  Contact Information:
                </h4>
                <input
                  type="text"
                  name="Contact Informaiton"
                  onChange={handleInputChange}
                  required
                  value={formData["Contact Informaiton"]}
                  id=""
                  className="border-t-0 border-r-0 border-l-0 border-b border-b-black w-full"
                />
              </div>
            </section>
            <article className="mt-10 flex items-center gap-4 mb-7">
              <div className="flex sm:items-end flex-col sm:flex-row  gap-[10px] justify-between mt-[3px]">
                <h4 className="relative font-medium bottom-[-7px]">
                  Application Signature:
                </h4>
                <input
                  type="file"
                  name="Application Signature"
                  id=""
                  onChange={handleInputChange}
                  required
                  value={formData["Application Signature"]}
                  className="border-t-0 border-r-0 border-l-0 border-b border-b-black w-full"
                />
              </div>
              <div className="flex sm:items-end flex-col sm:flex-row  gap-[10px] justify-between mt-[3px]">
                <h4 className="relative font-medium bottom-[-7px]">Date:</h4>
                <input
                  type="text"
                  name="Date-signature"
                  onChange={handleInputChange}
                  required
                  value={formData["Date-signature"]}
                  id=""
                  className="border-t-0 border-r-0 border-l-0 border-b border-b-black w-full"
                />
              </div>
            </article>
            <div className="w-full flex items-end justify-end mb-[30px]">
              <button
                type="submit"
                className="w-[140px] flex items-center justify-center mt-[32px] h-[40px] text-white rounded-md bg-black/50"
              >
                Submit
              </button>
            </div>
          </form>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default Job;
