import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { AiFillMail, AiFillPhone } from "react-icons/ai";
import { BiPhoneCall } from "react-icons/bi";
import { FaFax } from "react-icons/fa";
import { IoLocationSharp } from "react-icons/io5";
import { TbWorld } from "react-icons/tb";
import axios from "axios";
import VerifiedSeal from "./VerifiedSeal";

const Contact = ({ toast }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const submitHandler = async (e) => {
    e.preventDefault();
    setName("");
    setEmail("");
    setMessage("");
    setLoading(true);
    try {
      await axios.post(`http://localhost:8000/api/email/feedback`, {
        name,
        email,
        message,
      });
      setLoading(false);
      toast.success("Feedback Submitted");
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  return (
    <div id="contact">
      <AnimatePresence exitBeforeEnter>
        <div className="w-full max-w-[85rem] h-full py-5 mx-auto px-8 text-black bg-white lg:flex">
          <div className="w-full h-full px-3 pt-8">
            <div className="flex flex-col items-center justify-center w-full space-y-4">
              <div className="w-full h-40 ">
                <motion.h1
                  initial={{ y: 20, opacity: 0 }}
                  whileInView={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.7 }}
                  className="large-texts  text-center text-[48px]"
                >
                  <span className=" font-semibold  text-[60px] text-black">
                    Contact Us
                  </span>
                </motion.h1>
                <motion.p
                  initial={{ y: 20, opacity: 0 }}
                  whileInView={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.8 }}
                  className="text-[20px] text-black text-center"
                >
                  stay connected!
                </motion.p>
              </div>
            </div>
            <div className="w-full h-66 space-y-3 max-w-[65rem] mx-auto  my-20">
              <div className="flex items-center w-full space-x-4 text-lg">
                <IoLocationSharp className="w-6 h-6" />
                <p className="text:xs">
                  Address: 1404 Crain Highway South Suite 112 Glen Burnie MD
                  21061
                </p>
              </div>

              <div>
                <iframe
                  className="w-full my-12 lg:w-full h-60"
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12376.59497295595!2d-76.6324275!3d39.1485984!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c8170723ae5109%3A0xa28c9703ff6f42ab!2sNovum%20Wellness%20Center!5e0!3m2!1sen!2sng!4v1700387526469!5m2!1sen!2sng"
                  loading="lazy"
                ></iframe>
              </div>

              <div className="flex items-center w-full space-x-4 text-lg">
                <AiFillPhone className="w-6 h-6" />
                <h1>443-805-2790</h1>
              </div>
              <div className="flex items-center w-full space-x-4 text-lg">
                <FaFax className="w-6 h-6" />
                <h1>208-213-2790</h1>
              </div>
              <div className="flex items-center w-full space-x-4 text-lg">
                <AiFillMail className="w-6 h-6" />
                <a
                  href="mailto:info@novumhealthservices.com"
                  className="text-blue-600 underline"
                >
                  info@novumhealthservices.com
                </a>
              </div>
              <div className="flex items-center w-full space-x-4 text-lg">
                <TbWorld className="w-6 h-6" />
                <a
                  href="http://www.novumhealthservices.com/"
                  target="_"
                  className="text-blue-600 underline"
                >
                  www.novumhealthservices.com
                </a>
              </div>

              <div className="mt-4">
                <VerifiedSeal />
                <p className="">All programs are CARF-accredited.</p>
              </div>
            </div>
            <form
              onSubmit={(e) => {
                submitHandler(e);
              }}
              className="w-full space-y-7 max-w-[65rem] mx-auto "
            >
              <div className="flex flex-col w-full">
                <h1 className="text-xl">Name</h1>
                <input
                  type="text"
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  value={name}
                  required
                  className="px-3 py-2 bg-transparent border-b outline-none white"
                />
              </div>
              <div className="flex flex-col w-full">
                <h1 className="text-xl">Email Address</h1>
                <input
                  type="email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  value={email}
                  required
                  className="px-3 py-2 bg-transparent border-b outline-none white"
                />
              </div>
              <div className="flex flex-col w-full">
                <h1 className="text-xl">Message</h1>
                <textarea
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                  value={message}
                  className="px-3 py-2 bg-transparent border-b outline-none resize-none white"
                ></textarea>
              </div>

              {loading ? (
                <button
                  disabled
                  class="px-4 py-4 font-medium tracking-wide text-white capitalize transition-colors bg-gray-200 rounded-lg cursor-not-allowed"
                >
                  Send Feedback
                </button>
              ) : (
                <button class="px-4 py-4 font-medium tracking-wide text-black  capitalize transition-colors duration-300 transform border-2 border-black rounded-lg ">
                  <p className="font-bold">Send Feedback</p>
                </button>
              )}
            </form>
          </div>
        </div>
      </AnimatePresence>
    </div>
  );
};

export default Contact;
