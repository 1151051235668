import { AnimatePresence } from "framer-motion";
import { Route, Routes, useLocation } from "react-router-dom";
import Appointment from "./Pages/Appointment";
import Team from "./Pages/Team";
import Home from "./Pages/Home";
import Testimonials from "./Pages/Testimonials";
import Job from "./Pages/Job";
import Prp from "./Pages/Prp";
import "tailwindcss/tailwind.css";


// const routes = [{ path: "", element: <Home /> }];
function App() {
  // const element = useRoutes(routes);
  const location = useLocation();
  return (
    <div className="App bg-white">
        <AnimatePresence mode="wait" initial={false}>
          <Routes location={location} key={location.pathname}>
            <Route path="/" element={<Home />} />
            <Route path="appointment" element={<Appointment />} />
            <Route path="team" element={<Team />} />
            <Route path="testimonials" element={<Testimonials />} />
            <Route path="job" element={<Job />} />
            <Route path="prp" element={<Prp />} />
          </Routes>
        </AnimatePresence>
      {/* <SideLinks /> */}
    </div>
  );
}

export default App;
